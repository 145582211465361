<template>
    <div class="has-text-centered">
        <div class="container" style="margin-top: 150px">
            <figure class="image is-centered" style="margin:0px auto; width: 120px; height: 50; padding-bottom: 20px;">
                <img src="../../public/images/logo.png">
            </figure>
            <div class="notification is-centered" style="margin: auto 0px">
                <h1 class="subtitle" style="font-weight: bold;">Unsere Homepage befindet sich zurzeit im Umbau.
                </h1>
                <h5 style="font-size: 24sp;">Wir freunen uns Sie bald wieder begrüßen zu dürfen.</h5>

                <br>
                <br>
                <h1 class="subtitle" style="font-weight: bold;">Our Homepage is currently under maintenance.
                </h1>
                <h5 style="font-size: 24sp;">We will be back soon.</h5>
            </div>
        </div>
    </div>
</template>
<script lang="ts" setup>

</script>
<style lang="scss">

</style>