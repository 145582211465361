import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";
import ContactView from "../views/pages/wipa/ContactView.vue";
import ProjectOverview from "../views/ProjectOverview.vue";
import ImprintView from "../views/pages/wipa/ImprintView.vue";
import AboutView from "../views/pages/wipa/AboutView.vue";
import ProjectDetail from "../views/pages/wipa/ProjectDetail.vue";
import Property from "../views/pages/immo/Property.vue";
import PropertyOverview from "../views/pages/immo/PropertyOverview.vue"
import ImmoLogin from "../views/pages/immo/ImmoLogin.vue";
import Maintenance from "../views/Maintenance.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: Maintenance,
  }, {
    path: "/impressum",
    name: "impressum",
    component: ImprintView,
  },
  {
    path: "/contact",
    name: "contact",
    component: ContactView,
  },
  // {
  //   path: "/about",
  //   name: "about",
  //   component: AboutView,
  // },
  // {

  // {
  //   path: "/projects",
  //   name: "projects",
  //   component: ProjectOverview,
  // },
  // {
  //   path: "/projects/topics/:id",
  //   name: "/topics",
  //   component: ProjectDetail,
  // },
  // {

  // {
  //   path: "/immo",
  //   name: "immo",
  //   component: Property,
  //   redirect: { name: "dashboard" },
  //   children: [{
  //     path: "dashboard",
  //     name: "dashboard",
  //     component: PropertyOverview
  //   }, {
  //     path: "login",
  //     name: "login",
  //     component: ImmoLogin
  //   }]
  // },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
