import { createApp } from "vue";
import Maintenance from "./views/Maintenance.vue";
import router from "./router";
import VueSupabase from 'vue-supabase'
require("@/assets/main.scss");

/* Set up using Vue 3 */

/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";
/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
/* import specific icons */
import { faAddressCard, faArrowUpFromWaterPump, faCheck, faEnvelope, faEnvelopeCircleCheck, faExclamationTriangle, faFireFlameCurved, faFireFlameSimple, faNeuter, faPersonDigging, faSolarPanel, faUser, faUserSecret, faWater, faWind } from "@fortawesome/free-solid-svg-icons";
import { faHouse } from "@fortawesome/free-solid-svg-icons";
import { faRProject } from "@fortawesome/free-brands-svg-icons";
/* add icons to the library */
library.add(faUserSecret);
library.add(faHouse);
library.add(faAddressCard);
library.add(faRProject);
library.add(faPersonDigging)
library.add(faFireFlameSimple)
library.add(faSolarPanel)
library.add(faFireFlameCurved)
library.add(faArrowUpFromWaterPump)
library.add(faNeuter)
library.add(faEnvelopeCircleCheck)
library.add(faWater)
library.add(faWind)
library.add(faEnvelope)
library.add(faExclamationTriangle)
library.add(faUser)
library.add(faCheck)

createApp(Maintenance)
    .component("font-awesome-icon", FontAwesomeIcon)
    .use(router)
    .use(VueSupabase, {
        supabaseUrl: 'https://qejdyraivapwyklhgzbc.supabase.co',
        supabaseKey: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InFlamR5cmFpdmFwd3lrbGhnemJjIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NjM0MDI3ODYsImV4cCI6MTk3ODk3ODc4Nn0.xTHG3fJTolY5AvXep0qNtMHyqA75pPzJl3X0p0Emt8s',
        supabaseOptions: {}
      })
    .mount("#app");
